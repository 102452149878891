import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Head from "../components/head"
import PageLayout from "../components/hrbfpagelayout"
import MarketoForm from "../components/marketoform"

export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      registrationUrl
      registrationCtaText
      transparentHeader
      headerColor
      backgroundImage {
        file {
          url
        }
      }
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      formId {
        stayInformed
      }
      seoMetadata {
        title
        description {
          description
        }
      }
    }
  }
`

const SignUpForUpdates = props => {
  let registerUrl
  if (
    typeof window !== "undefined" &&
    props?.data?.contentfulEvents?.registrationUrl
  ) {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl?.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })

  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      bannerBackground={props?.data?.contentfulEvents?.backgroundImage}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={props?.data?.contentfulEvents?.seoMetadata?.title}
        description={
          props?.data?.contentfulEvents?.seoMetadata?.description?.description
        }
      />
      <div className="sponsors_fr_body">
        <div className="sponsors_fr_back">
          {/* <Link to={`/events/${props?.data?.contentfulEvents?.slug}/about`}>
            <span>&lt;</span> &nbsp;&nbsp;&nbsp;&nbsp;About
          </Link> */}
        </div>
        <h2 className="sponsors_fr_title">Sign Up for Updates</h2>
        <h3 className="sponsors_fr_subtitle">
          Complete this form to receive updates
        </h3>

        <div className="sponsors_fr_main">
          <MarketoForm
            formId={props?.data?.contentfulEvents?.formId?.stayInformed}
            formType="sign-up"
          />
        </div>
      </div>
    </PageLayout>
  )
}
export default SignUpForUpdates
